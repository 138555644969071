import { serializeError } from 'serialize-error';
export const logError = async (
  err: Error | string | unknown,
  id = generateRandomId(),
  componentStack?: string,
  message?: string
) => {
  try {
    console.error(err, id, componentStack, message);
    if (process.env.NODE_ENV === 'development') return;
    if (typeof err === 'string') err = new Error(err);
    const ctx = serializeError(err);
    if (componentStack) ctx.stack = componentStack;
    const body = JSON.stringify({
      ...serializeError(err),
      id,
      ...(message && { message }),
    });
    await fetch('/api/error', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    });
  } catch (e) {
    console.error('Error reporting error', e);
  }
};

export const generateRandomId = () => {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 24; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};
